
import { Options, Vue, prop } from "vue-class-component";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import {
  formatDateNumber,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import {
  DoneDexAssessment,
  SpeedDexAssessment,
  SummaryTotalCs,
} from "@/domain/entities/DexAssessment";
import convertMinutesToHoursAndMinutes from "@/app/infrastructures/misc/common-library/ConvertMinutesToHoursAndMinutes";

class Props {
  title = prop<string>({
    default: "",
    type: String,
  });
}
@Options({
  components: {
    DetailStatusCardDashboardClient,
  },
})
export default class SectionTotalCSDex extends Vue.with(Props) {
  get filter() {
    return DexAssessmentController.filter;
  }

  totalCsData: SummaryTotalCs = new SummaryTotalCs({
    highestDoneDexAssessment: new DoneDexAssessment(),
    lowestDoneDexAssessment: new DoneDexAssessment(),
    fastestDexAssessment: new SpeedDexAssessment(),
    slowestDexAssessment: new SpeedDexAssessment(),
  });

  convertMinutesToHoursAndMinutes(val: any) {
    return convertMinutesToHoursAndMinutes(val)
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.dashboardData.loading = true;
      const { data: summaryTicket } =
        await DexAssessmentController.getSummaryTotalCs(
          new ApiRequestList({
            startDate: formatDateNumber(startDate),
            endDate: formatDateNumber(endDate),
          })
        );
      this.onMappingSummaryTotalCs(summaryTicket);
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
  onMappingSummaryTotalCs(data: any): void {
    this.totalCsData = data;
  }
}
