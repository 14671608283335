
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Options, Vue } from "vue-class-component";
import SectionTotalTicketBasedRangeTime from "../components/section-total-ticket-based-range-time.vue";
import SectionTableExpand from "../components/section-table-expand.vue";
import { OptionsClass } from "@/domain/entities/MainApp";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { ReasonData } from "@/domain/entities/PodDex";
import Checkbox from "primevue/checkbox";
import SectionTicketPerDay from "../components/section-ticket-per-day.vue";
import SectionSummaryByReason from "../components/section-summary-by-reason.vue";

@Options({
  components: {
    SectionTotalTicketBasedRangeTime,
    SectionTableExpand,
    Checkbox,
    SectionTicketPerDay,
    SectionSummaryByReason
  }
})
export default class DexAssesmentTab extends Vue {
  mounted(): void {
    this.fetchReason();
    this.onFetchSummaryBreakdownTicketDex();
  }

  get filter() {
    return DexAssessmentController.filter;
  }

  // reason
  async fetchReason() {
    await PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "DEX",
        reasonStatus: "active"
      })
    );
  }
  get reasonData(): OptionsClass[] {
    return PodDexController.reasonList.map(
      (key: ReasonData) =>
        new OptionsClass({
          name: key.reasonDescription,
          value: key.reasonCode
        })
    );
  }
  get totalReasonValue(): string {
    if (this.filter.reasonCode.length) {
      return `${this.filter.reasonCode.length} Alasan DEX`;
    }
    return "";
  }

  onFetchSummaryBreakdownTicketDex() {
    this.filter.clickFilter = true;
    this.fetchSummaryTopDex();
    this.fetchSummaryTicketBasedRangeTime();
    this.fetchTotalTicketPerDay();
  }

  async fetchSummaryTicketBasedRangeTime() {
    const refs: any = this.$refs["ticket-based-range-time"];
    if (refs) {
      refs.fetchSummaryTicketBasedRangeTime();
    }
  }
  async fetchTotalTicketPerDay() {
    const refs: any = this.$refs["total-ticket"];
    if (refs) {
      refs.fetchData();
    }
  }

  async fetchSummaryTopDex() {
    const refs: any = this.$refs["table-expand"];
    const byReasonRefs: any = this.$refs["by-reason"];
    if (refs) {
      refs.fetchSummaryTopDex("partner");
      byReasonRefs.fetchData();
    }
  }

  onDownload() {
    DexAssessmentController.getDownload("summary-breakdown-download");
  }
}
