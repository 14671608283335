import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "balance-list bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full font-montserrat" }
const _hoisted_2 = {
  class: "overflow-auto pb-8",
  style: {"height":"100vh"}
}
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "mt-8 flex flex-wrap items-center justify-between" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_SectionTotalCSDex = _resolveComponent("SectionTotalCSDex")!
  const _component_SectionTableAgentList = _resolveComponent("SectionTableAgentList")!
  const _component_SectionBacklogAgent = _resolveComponent("SectionBacklogAgent")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.filter.startDate,
              endDate: _ctx.filter.endDate,
              onSelect: _ctx.setDateRange,
              default: "hari ini",
              isCloseDateAfterSelect: "",
              leftPosition: false,
              maxDate: new Date()
            }, null, 8, ["startDate", "endDate", "onSelect", "maxDate"]),
            _createVNode(_component_lp_button, {
              icon: "download-new",
              outline: "",
              small: "",
              onClick: _ctx.onDownload
            }, null, 8, ["onClick"])
          ])
        ]),
        _createVNode(_component_SectionTotalCSDex, {
          title: "Total CS DEX Asesmen",
          type: "all",
          loading: _ctx.dashboardData.loading,
          ref: "total-ticket-all"
        }, null, 8, ["loading"]),
        _createVNode(_component_SectionTableAgentList, { ref: "table-agent" }, null, 512),
        _createVNode(_component_SectionBacklogAgent, { ref: "backlog-agent" }, null, 512)
      ])
    ])
  ]))
}