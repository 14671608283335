import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full gap-4" }
const _hoisted_2 = { class: "p-4 flex flex-col gap-6" }
const _hoisted_3 = { class: "grid grid-cols-3 divide-x" }
const _hoisted_4 = {
  key: 0,
  class: "px-8"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_6 = { class: "text-24px font-semibold" }
const _hoisted_7 = {
  key: 2,
  class: "px-8"
}
const _hoisted_8 = {
  key: 3,
  class: "px-8"
}
const _hoisted_9 = { class: "text-24px font-semibold" }
const _hoisted_10 = {
  key: 4,
  class: "px-8"
}
const _hoisted_11 = {
  key: 5,
  class: "pl-8"
}
const _hoisted_12 = { class: "text-24px font-semibold" }
const _hoisted_13 = { class: "grid grid-cols-4 divide-x p-4" }
const _hoisted_14 = {
  key: 0,
  class: "px-8 text-aqua-lp-500"
}
const _hoisted_15 = {
  key: 1,
  class: "pr-4 flex flex-col"
}
const _hoisted_16 = { class: "flex flex-row gap-x-2" }
const _hoisted_17 = { class: "text-14px font-semibold mt-2" }
const _hoisted_18 = {
  key: 2,
  class: "px-8"
}
const _hoisted_19 = {
  key: 3,
  class: "px-4 flex flex-col"
}
const _hoisted_20 = { class: "flex flex-row gap-x-2" }
const _hoisted_21 = { class: "text-14px font-semibold mt-2" }
const _hoisted_22 = {
  key: 4,
  class: "px-8"
}
const _hoisted_23 = {
  key: 5,
  class: "px-4 flex flex-col"
}
const _hoisted_24 = { class: "flex flex-row gap-x-2" }
const _hoisted_25 = { class: "text-14px font-semibold mt-2" }
const _hoisted_26 = {
  key: 6,
  class: "px-8"
}
const _hoisted_27 = {
  key: 7,
  class: "px-4 flex flex-col"
}
const _hoisted_28 = { class: "flex flex-row gap-x-2" }
const _hoisted_29 = { class: "text-14px font-semibold mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Chips = _resolveComponent("Chips")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "w-full" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_TooltipV2, {
              text: _ctx.title,
              fontSize: 14,
              fontWeight: "semibold"
            }, null, 8, ["text"]),
            _createVNode("div", _hoisted_3, [
              (_ctx.dashboardData.loading)
                ? (_openBlock(), _createBlock("div", _hoisted_4, [
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    }),
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    })
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_5, [
                    _createVNode(_component_TooltipV2, {
                      text: "Total Customer Service",
                      fontSize: 12,
                      fontWeight: "semibold",
                      textColor: "gray-lp-800"
                    }),
                    _createVNode("div", _hoisted_6, _toDisplayString(_ctx.totalCsData.totalCs), 1)
                  ])),
              (_ctx.dashboardData.loading)
                ? (_openBlock(), _createBlock("div", _hoisted_7, [
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    }),
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    })
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_8, [
                    _createVNode(_component_TooltipV2, {
                      text: "Total Customer Service L1",
                      fontSize: 12,
                      fontWeight: "semibold",
                      textColor: "gray-lp-800"
                    }),
                    _createVNode("div", _hoisted_9, _toDisplayString(_ctx.totalCsData.totalCsL1), 1),
                    _createVNode(_component_ProgressBar, {
                      value: _ctx.totalCsData.totalCsL1,
                      class: "w-full mr-2",
                      startValue: 0,
                      endValue: _ctx.totalCsData.totalCs,
                      customColor: "#FFCB01"
                    }, null, 8, ["value", "endValue"])
                  ])),
              (_ctx.dashboardData.loading)
                ? (_openBlock(), _createBlock("div", _hoisted_10, [
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    }),
                    _createVNode(_component_Skeleton, {
                      class: "mt-2",
                      height: "1rem"
                    })
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_11, [
                    _createVNode(_component_TooltipV2, {
                      text: "Total Customer Service L2",
                      fontSize: 12,
                      fontWeight: "semibold",
                      textColor: "gray-lp-800"
                    }),
                    _createVNode("div", _hoisted_12, _toDisplayString(_ctx.totalCsData.totalCsL2), 1),
                    _createVNode(_component_ProgressBar, {
                      value: _ctx.totalCsData.totalCsL2,
                      class: "w-full mr-2",
                      startValue: 0,
                      endValue: _ctx.totalCsData.totalCs,
                      customColor: "#7ACB90"
                    }, null, 8, ["value", "endValue"])
                  ]))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Card, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_13, [
          (_ctx.dashboardData.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_14, [
                _createVNode(_component_Skeleton, {
                  class: "mt-2 bg-aqua-lp-400",
                  height: "1rem"
                }),
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                })
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_15, [
                _createVNode(_component_TooltipV2, {
                  text: "Penyelesaian Tercepat",
                  fontSize: 14,
                  fontWeight: "semibold"
                }),
                _createVNode("div", _hoisted_16, [
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-200",
                    textColor: "aqua-lp-500",
                    label: 
              _ctx.totalCsData.fastestDexAssessment.csName !== ''
                ? _ctx.totalCsData.fastestDexAssessment.csName
                : '-'
            ,
                    rounded: ""
                  }, null, 8, ["label"]),
                  _createVNode("div", _hoisted_17, _toDisplayString(_ctx.convertMinutesToHoursAndMinutes(_ctx.totalCsData.fastestDexAssessment.timeMinute)), 1)
                ])
              ])),
          (_ctx.dashboardData.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_18, [
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                }),
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                })
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_19, [
                _createVNode(_component_TooltipV2, {
                  text: "Penyelesaian Terlama",
                  fontSize: 14,
                  fontWeight: "semibold"
                }),
                _createVNode("div", _hoisted_20, [
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-200",
                    textColor: "aqua-lp-500",
                    label: 
              _ctx.totalCsData.slowestDexAssessment.csName !== ''
                ? _ctx.totalCsData.slowestDexAssessment.csName
                : '-'
            ,
                    rounded: ""
                  }, null, 8, ["label"]),
                  _createVNode("div", _hoisted_21, _toDisplayString(_ctx.convertMinutesToHoursAndMinutes(_ctx.totalCsData.slowestDexAssessment.timeMinute)), 1)
                ])
              ])),
          (_ctx.dashboardData.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_22, [
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                }),
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                })
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_23, [
                _createVNode(_component_TooltipV2, {
                  text: "Persentase Selesai Terbanyak",
                  fontSize: 14,
                  fontWeight: "semibold"
                }),
                _createVNode("div", _hoisted_24, [
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-200",
                    textColor: "aqua-lp-500",
                    label: 
              _ctx.totalCsData.highestDoneDexAssessment.csName !== ''
                ? _ctx.totalCsData.highestDoneDexAssessment.csName
                : '-'
            ,
                    rounded: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-400",
                    textColor: "white",
                    label: `${_ctx.totalCsData.highestDoneDexAssessment.percentage}%`
                  }, null, 8, ["label"]),
                  _createVNode("div", _hoisted_25, _toDisplayString(_ctx.totalCsData.highestDoneDexAssessment.total) + " Tiket ", 1)
                ])
              ])),
          (_ctx.dashboardData.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_26, [
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                }),
                _createVNode(_component_Skeleton, {
                  class: "mt-2",
                  height: "1rem"
                })
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_27, [
                _createVNode(_component_TooltipV2, {
                  text: "Persentase Selesai Tersedikit",
                  fontSize: 14,
                  fontWeight: "semibold"
                }),
                _createVNode("div", _hoisted_28, [
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-200",
                    textColor: "aqua-lp-500",
                    label: 
              _ctx.totalCsData.lowestDoneDexAssessment.csName !== ''
                ? _ctx.totalCsData.lowestDoneDexAssessment.csName
                : '-'
            ,
                    rounded: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    weight: "semibold",
                    color: "aqua-lp-400",
                    textColor: "white",
                    label: `${_ctx.totalCsData.lowestDoneDexAssessment.percentage}%`
                  }, null, 8, ["label"]),
                  _createVNode("div", _hoisted_29, _toDisplayString(_ctx.totalCsData.lowestDoneDexAssessment.total) + " Tiket ", 1)
                ])
              ]))
        ])
      ]),
      _: 1
    })
  ], 64))
}