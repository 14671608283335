
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Options, Vue } from "vue-class-component";
import SectionTotalCSDex from "../components/section-total-cs-dex-assessment.vue";
import SectionBreakdownTicketDex from "../components/section-breakdown-ticket-dex.vue";
import SectionTableAgentList from "../components/section-table-agent-list.vue";
import SectionBacklogAgent from "../components/section-backlog-ticket-cs.vue";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import Checkbox from "primevue/checkbox";
import capitalize from "lodash/capitalize";

@Options({
  components: {
    SectionTotalCSDex,
    SectionBreakdownTicketDex,
    Checkbox,
    SectionTableAgentList,
    SectionBacklogAgent
  }
})
export default class PerformaAgentTab extends Vue {
  grouppedBarChart = {
    series: [
      {
        name: "0 Hari",
        data: [44, 55, 41]
      },
      {
        name: "1 Hari",
        data: [53, 0, 33]
      },
      {
        name: "2 hari",
        data: [0, 64, 51]
      },
      {
        name: "3 hari",
        data: [0, 64, 51]
      },
      {
        name: "4 hari",
        data: [0, 64, 51]
      },
      {
        name: "5 hari",
        data: [0, 64, 51]
      }
    ],
    categories: ["Gibran", "Farid", "Fuad"],
    color: ["#FFEB9F", "#FFCB01", "#F09035"],
    rounded: false,
    stacked: false,
    barHeight: "75%",
    labelBarPosition: "top"
  };

  get title(): string {
    return (
      DexAssessmentController.dashboardTabs.find(
        e => e.path === DexAssessmentController.dashboardCurrentTabActive
      )?.title || ""
    );
  }

  // date
  get filter() {
    return DexAssessmentController.filter;
  }
  async setDateRange(value: any[]) {
    const totalTicketAll: any = this.$refs["total-ticket-all"];
    const tableAgentRefs: any = this.$refs["table-agent"]
    const backlogAgentRefs: any = this.$refs["backlog-agent"]

    DexAssessmentController.setStartDate(value[0]);
    DexAssessmentController.setEndDate(value[1]);

    const valid = value[0] && value[1];
    if (valid) {
      totalTicketAll.fetchData();
      tableAgentRefs.fetchListCsL1();
      tableAgentRefs.fetchListCsL2();
      backlogAgentRefs.fetchData();
    }
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  capitalize(value: string) {
    return capitalize(value);
  }

  onDownload() {
    DexAssessmentController.getDownload("summary-performance-cs/download");
  }
}
