
import { Options, Vue, prop } from "vue-class-component";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import {
  formatDateNumber,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";

class Props {
  title = prop<string>({
    default: "",
    type: String,
  });
}
@Options({
  components: {
    DetailStatusCardDashboardClient,
  },
})
export default class SectionBacklogAgent extends Vue.with(Props) {
  get filter() {
    return DexAssessmentController.filter;
  }
  isLoading = false;
  errorMessage = "";

  grouppedBarChartL1 = {
    series: [] as {
      name: string;
      data: any[];
    }[],
    categories: [] as string[],
    color: ["#F5F5F5","#FEFAE8", "#FFEB9F", "#FFDD5A", "#FFCB01","#FAB713", "#F5A324", "#F09035"],
    rounded: false,
    stacked: false,
    height: 1000,
    barHeight: "70%",
    labelBarPosition: "top",
  };
  grouppedBarChartL2 = {
    series: [] as {
      name: string;
      data: any[];
    }[],
    categories: [] as string[],
    color: ["#F5F5F5","#FEFAE8", "#FFEB9F", "#FFDD5A", "#FFCB01","#FAB713", "#F5A324", "#F09035"],
    rounded: false,
    stacked: false,
    height: 1000,
    barHeight: "70%",
    labelBarPosition: "top",
  };
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.isLoading = true;
      const { data: chartL1 } = await DexAssessmentController.getBacklogAgent(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: "l1",
        })
      );
      const { data: chartL2 } = await DexAssessmentController.getBacklogAgent(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: "l2",
        })
      );
      this.onMappingDataGroupedBarChartL1(chartL1);
      this.onMappingDataGroupedBarChartL2(chartL2);
    } catch (error) {
      this.errorMessage = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }
  onMappingDataGroupedBarChartL1(data: any): void {
    const transformedData: any = {
      series: [],
      categories: [],
    };

    data.labels.forEach((label: any, index: number) => {
      if (index < data.labels.length) {
        transformedData.series.push({
          name: label,
          data: data.datasets.map((dataset: any) => {
            return dataset.data[index];
          }),
        });
      }
    });

    transformedData.categories = data.datasets.map((dataset: any) => {
      return dataset.labels;
    });

    const height = data.datasets.length * 250;

    this.grouppedBarChartL1 = {
      ...this.grouppedBarChartL1,
      categories: transformedData.categories,
      series: transformedData.series,
      height: height
    };
  }
  onMappingDataGroupedBarChartL2(data: any): void {
    const transformedData: any = {
      series: [],
      categories: [],
    };

    data.labels.forEach((label: any, index: number) => {
      if (index < data.labels.length) {
        transformedData.series.push({
          name: label,
          data: data.datasets.map((dataset: any) => {
            return dataset.data[index];
          }),
        });
      }
    });

    transformedData.categories = data.datasets.map((dataset: any) => {
      return dataset.labels;
    });

    const height = data.datasets.length * 250;

    this.grouppedBarChartL2 = {
      ...this.grouppedBarChartL2,
      categories: transformedData.categories,
      series: transformedData.series,
      height: height
    };
  }
}
