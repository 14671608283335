import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_2 = { class: "flex flex-row gap-4 justify-between items-center" }
const _hoisted_3 = { class: "flex flex-row items-center gap-4 w-1/2" }
const _hoisted_4 = { class: "px-2 py-2 space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_custom_dropdown = _resolveComponent("custom-dropdown")!
  const _component_SelectCity = _resolveComponent("SelectCity")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_SectionTableExpand = _resolveComponent("SectionTableExpand")!
  const _component_SectionSummaryByReason = _resolveComponent("SectionSummaryByReason")!
  const _component_SectionTicketPerDay = _resolveComponent("SectionTicketPerDay")!
  const _component_SectionTotalTicketBasedRangeTime = _resolveComponent("SectionTotalTicketBasedRangeTime")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Title, { label: "Breakdown Tiket DEX" }),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_custom_dropdown, {
              placeholder: "Pilih Alasan DEX",
              value: _ctx.totalReasonValue,
              class: "w-1/3"
            }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_4, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.reasonData, (reason, index) => {
                    return (_openBlock(), _createBlock("div", {
                      key: index,
                      class: "p-field-checkbox mt-1 flex flex-row"
                    }, [
                      _createVNode(_component_Checkbox, {
                        name: "reason-dex",
                        id: index,
                        value: reason.value,
                        modelValue: _ctx.filter.reasonCode,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter.reasonCode = $event)),
                        onChange: _ctx.onFetchSummaryBreakdownTicketDex
                      }, null, 8, ["id", "value", "modelValue", "onChange"]),
                      _createVNode("label", {
                        for: String(index),
                        class: "ml-2.5"
                      }, _toDisplayString(reason.value) + " - " + _toDisplayString(reason.name), 9, ["for"])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_SelectCity, {
              status: "active",
              placeholder: "Ketik atau pilih 3LC",
              modelValue: _ctx.filter.city,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter.city = $event)),
              onChange: _ctx.onFetchSummaryBreakdownTicketDex,
              class: "w-1/3"
            }, null, 8, ["modelValue", "onChange"]),
            _createVNode(_component_lp_button, {
              icon: "download-new",
              outline: "",
              small: "",
              onClick: _ctx.onDownload
            }, null, 8, ["onClick"])
          ])
        ]),
        _createVNode(_component_SectionTableExpand, {
          title: "Top 20 Konsol Tiket DEX Terbanyak",
          ref: "table-expand"
        }, null, 512),
        _createVNode(_component_SectionSummaryByReason, { ref: "by-reason" }, null, 512),
        _createVNode(_component_SectionTicketPerDay, { ref: "total-ticket" }, null, 512),
        _createVNode(_component_SectionTotalTicketBasedRangeTime, { ref: "ticket-based-range-time" }, null, 512)
      ])
    ]),
    _: 1
  }))
}