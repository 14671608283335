
import { Options, Vue, prop } from "vue-class-component";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import {
  formatDateNumber,
  formatPriceWithoutCurrency,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import convertMinutesToHoursAndMinutes from "@/app/infrastructures/misc/common-library/ConvertMinutesToHoursAndMinutes";

class Props {
  title = prop<string>({
    default: "",
    type: String
  });
}
@Options({
  components: {
    DetailStatusCardDashboardClient
  }
})
export default class SectionTableAgentList extends Vue.with(Props) {
  mounted() {
    this.filter.sortListCsL1 = new OptionsClass();
    this.filter.sortListCsL2 = new OptionsClass();
    this.filter.searchCsL1 = "";
    this.filter.searchCsL2 = "";
  }
  get filter() {
    return DexAssessmentController.filter;
  }
  isLoadingL1 = false;
  isLoadingL2 = false;
  errorMessage = "";

  listDataCsL1 = [];
  listDataCsL2 = [];
  columns = [
    {
      name: "No",
      styleHead: "w-2/20 text-left",
      render: (item: any, index: number) =>
        `<span class='text-left text-black-lp-300 flex'>${index + 1}</span>`
    },
    {
      name: "Nama CS",
      styleHead: "w-1/4 text-left",
      render: (item: any) =>
        `<span class='text-left text-black-lp-300 flex'>${item.name}</span>`
    },
    {
      name: "Status",
      styleHead: "w-1/5 text-left",
      render: (item: any) =>
        `<span class='text-left text-black-lp-300 flex'>${
          item.status.toLowerCase() === "active"
            ? ChipsStyles({ title: "Aktif", status: "green" })
            : ChipsStyles({ title: "Tidak Aktif", status: "red" })
        }</span>`
    },
    {
      name: "Kuota Tiket",
      styleHead: "w-1/5 text-left",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${item.ticketQuota}</span>`
    },
    {
      name: "Tiket Diassign",
      styleHead: "w-1/5 text-left whitespace-nowrap",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${item.ticketAssigned}</span>`
    },
    {
      name: "Tiket Selesai",
      styleHead: "w-1/5 text-left",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${item.ticketDone}</span>`
    },
    {
      name: "Persentase Tiket Selesai",
      styleHead: "w-2/5 text-left",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${this.formatDecimal(
          item.percentageDone
        )}%</span>`
    },
    {
      name: "Rata Rata Waktu Selesai",
      styleHead: "w-2/5 text-left",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${convertMinutesToHoursAndMinutes(
          item.averageDoneMinute
        )}</span>`
    },
    {
      name: "Total Jam Aktif",
      styleHead: "w-2/5 text-left text-black-lp-300",
      render: (item: any) =>
        `<span class='text-black-lp-300 flex'>${convertMinutesToHoursAndMinutes(
          item.totalActiveMinute
        )}</span>`
    }
  ];
  formatDecimal(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  async fetchListCsL1() {
    this.fetchListCs("l1");
  }
  async fetchListCsL2() {
    this.fetchListCs("l2");
  }
  async fetchListCs(type: "l1" | "l2") {
    const { startDate, endDate } = this.filter;

    try {
      if (type === "l1") {
        this.isLoadingL1 = true;
      } else {
        this.isLoadingL2 = true;
      }
      const {
        data: summaryTicket
      } = await DexAssessmentController.getAgentList(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: type, // l1 | l2
          csName: this.filter.searchCsL2, // filter cs name (optional)
          order: "percentage_done", // desc (optional)
          sort: this.filter.sortListCsL2.value // (optional)
        })
      );
      if (type === "l1") {
        this.onMappingListCsL1(summaryTicket);
      } else {
        this.onMappingListCsL2(summaryTicket);
      }
    } catch (error) {
      this.errorMessage = parsingErrorResponse(error).type;
    } finally {
      if (type === "l1") {
        this.isLoadingL1 = false;
      } else {
        this.isLoadingL2 = false;
      }
    }
  }
  onMappingListCsL1(data: any): void {
    this.listDataCsL1 = data;
  }
  onMappingListCsL2(data: any): void {
    this.listDataCsL2 = data;
  }

  // search
  onSearch(value: string, type: string) {
    if (!value || value.length >= 3) {
      if (type === "l1") {
        this.filter.searchCsL1 = value;
        this.fetchListCsL1();
      } else {
        this.filter.searchCsL2 = value;
        this.fetchListCsL2();
      }
    }
  }

  // status DEX Assesment
  get sortListCsL1Data(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Persentase Selesai Tertinggi",
        value: "desc"
      }),
      new OptionsClass({
        name: "Persentase Selesai Terendah",
        value: "asc"
      })
    ];
  }
  selectSortListCsL1(data: OptionsClass) {
    Object.assign(this.filter, { sortListCsL1: data });
    this.fetchListCsL1();
  }
  selectSortListCsL2(data: OptionsClass) {
    Object.assign(this.filter, { sortListCsL2: data });
    this.fetchListCsL2();
  }
}
